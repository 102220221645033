.nav{
    z-index: 99;
    height: 70px;
    width: 100vw;
    background-color:#DAE0E2;
    top: 0;
    position: fixed;
    h2{
        margin: 0;
        padding-top: 13px;
        padding-left: 20px;
        font-weight: 400;
    }
    a{
        float: right;
        transform: translateY(-20px);
        margin-right: 20px;
    }
    .icon{
        float: right;
        transform: translateY(-20px);
        color: purple;
        margin-right: 10px;
    }
    .icon:hover, .icon:active{
        color: blue;
        cursor: pointer;
    }
}
.about{
    text-align: center;
    h2, h3{
        font-weight: 400;
    }
    h4{
        padding-top: 0;
        margin-top: 0;
        color: grey;
    }
    p{
        opacity: 0.7;
        font-weight: 400;
    }
    .social-icon{
        transition: 0.28s all ease-in;
        transform: scale(1.1);
    }
    .social-icon:hover{
        transform: scale(1.5);
    }
}